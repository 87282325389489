// Fontawesome
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

// Fonts
@import 'fonts.scss';

// Variables
$color-blue: #003659;
$color-brown-title: #806553;
$color-brown-content: #4d3c32;
$bg-schlamm: #d7c7bb;
$bg-light: #f6f7f8;

// General
body{
    font-family: Ubuntu, sans-serif;
    font-size: 20px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    color: #707070;
    background: $bg-light;
    overflow-x: hidden !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

h1, .section-title{
    color: $color-brown-title;
    font-weight: 400;
    font-size: 34px;
    line-height: 44px;
    text-align: center;
}
h2, .section-title-medium{
    font-family: 'SegoePrint', serif;
    color: $color-blue;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -1px;
}
h3{
    color: $color-blue;
    font-size: 21px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: normal;
}
p{
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
    margin-bottom: 20px;
}

a{
    color: $color-blue;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    background-color: transparent;
    &:hover, &:focus{
        color: $color-blue;
        text-decoration: underline;
        text-decoration-style: dashed;
    }
    &:hover, &:active{
        outline: 0;
    }
}
b, strong{
    font-weight: 500;
    color: $color-blue;
}
::selection {
    background: #fefac7;
    color: #404040;
}
.btn{
    transition: all 150ms ease-in-out;
    font-size: 0.8125rem;
    font-weight: 500;
    border: none;
    border-radius: 0;
    padding: 12px 20px 11px;
}
.btn-secondary{
    background-color: $color-blue;
    text-transform: uppercase;
}

// Content
.content-wrapper{
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

// Content Left
.content-left {
    height: 50vh;
    max-height: 300px;
    background-position: center -30px;
    background-image: url('../../assets/images/gabriele-woellmann_copyright.jpg');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    @media (min-width: 451px) {
        max-height: 500px;
        background-position: center -70px;
    }
    @media (min-width: 768px) {
        max-height: 750px;
        background-position: center -70px;
    }
    @media (min-width: 1200px) {
        max-height: initial;
        width: 40%;
        position: fixed;
        height: 100vh;
        background-position: center center;
    }
}

// Content Right
.content-right{
    padding: 0;
    color: $color-brown-content;
    @media (min-width: 1200px) {
        margin-left: 40%;
        width: 60%;
    }
}
.section-wrapper{
    &.light-wrapper{
        background: $bg-light;
    }
    &.schlamm-wrapper{
        background: $bg-schlamm;
    }
    .inner{
        padding: 60px 20px;
        @media (max-width: 767px) {
            &:first-child{
                padding: 30px 20px;
            }
        }
        @media (min-width: 768px) {
            padding: 90px 100px;
        }
        @media (min-width: 1200px) {
            padding: 45px 50px;
        }
        @media (min-width: 1400px) {
            padding: 90px 100px;
        }
        .logo{
            width: 400px;
            margin-bottom: 45px;
            max-width: 100%;
        }
    }
}

// Newsletter Formular
.newsletter-form{
    input::placeholder{
        color: lightgrey;
    }
    .form-check-label{
        color: rgb(112,112,112);
        font-size: 1rem;
    }
}

// Ueber mich
.table-kompetenz{
    td{
        vertical-align: top;
        p:last-child{
            margin-bottom: 40px;
        }
    }
    input{
        width: 90px!important;
        height: auto!important;
        padding-right: 15px;
    }
}

// Blog
.section-blog{
    .blog-header-wrapper{
        margin-bottom: 3rem;
        .blog-item-first-box{
            position: relative;
            .image-box{
                position: relative;
                &:before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    background-color: rgba(0,0,0,.4);
                }
                .card-img-top{
                    height: 350px;
                    object-fit: cover;
                }
            }
        }
        .blog-item-intro-image{
            position: relative;
            .image-box{
                position: relative;
                &:before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    // background-color: rgba(0,0,0,.4);
                }
                .card-img-top{
                    height: 350px;
                    object-fit: cover;
                }
            }
        }
        .blog-item-first-body{
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            .card-created{
                color: #fff;
                font-size: 1rem;
            }
            .card-category{
                color: #fff;
                font-size: 1rem;
                text-transform: uppercase;
                margin-bottom: .6rem;
            }
            .card-title{
                color: #fff;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: normal;
            }
        }
        .menu-categories{
            list-style: none;
            margin-bottom: 0;
            align-self: center;
            @media (max-width: 767px) {
                padding-top: 2rem;
            }
            .nav-item{
                margin-bottom: 0.15rem;
                font-size: 1.2rem;
                .nav-link.active {
                    font-weight: 500;
                    color: $color-blue;
                }
                .nav-link:hover{
                    color: $color-blue;
                    font-weight: 500;
                    text-decoration: none;
                }

            }
        }
    }
    .card-wrapper{
        margin-bottom: 1rem;
        .card{
            height: 100%;
            a:hover{
                text-decoration: none;
            }
            .card-img-wrapper{
                position: relative;
                padding-top: 60%;
            }
            .card-img-top{
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
            .card-created{
                color: $color-brown-content;
                font-size: 1rem;
            }
            .card-category{
                color: $color-brown-content;
                font-size: 1rem;
                text-transform: uppercase;
                margin-bottom: .6rem;
            }
            .card-title{
                color: $color-blue;
                font-size: 1.2rem;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
    .blog-item{
        .item-created{
            color: $color-brown-content;
            font-size: 1rem;
        }
        .item-category{
            color: $color-brown-content;
            font-size: 1.2rem;
            text-transform: uppercase;
        }
        .item-title{
            color: $color-blue;
            font-weight: 500;
            line-height: inherit;
            font-size: 2.5rem;
            margin: 1rem 0;
        }
        .badge{
            margin-bottom: .6rem;
            font-weight: normal;
            color: $color-brown-content;
            background-color: $bg-schlamm;
        }
    }
}

// Angebote
.section-courses{
    .isotop-filter{
        text-align: center;
        ul{
            position: relative;
            z-index: 2;
            font-size: 1rem;
            padding: 0;
            margin-bottom: 10px;
            li{
                display: inline-block;
                padding: 0;
                margin: 0 1rem;
                cursor: pointer;
            }
        }
    }
    .grid-item{
        width: 100%;
        margin: 0 2% 1% 0;
        @media (min-width: 768px) {
            width: 48%;
        }
        figure.fyndery{
            position: relative;
            height: 100%;
            overflow: hidden;
            transition: all 0.4s ease-in-out;
            color: #ffffff !important;
            span.bg{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                cursor: pointer;
                z-index: 1;
                opacity: 0;
                background: rgba(0,0,0,0.4);
            }
            img{
                width: 100%;
            }
            .course-link{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 3;
            }
            figcaption{
                height: 100%;
                width: 100%;
                padding: 10px;
                position: absolute;
                left: 0;
                bottom: 0;
                top: auto;
                right: auto;
                z-index: 2;
                .title{
                    font-size: 20px;
                    line-height: normal;
                    color: $color-blue !important;
                    margin-bottom: 0;
                    font-family: Ubuntu, serif;
                    font-weight: 500;
                }
                .subtitle{
                    font-size: 15px;
                    line-height: 20px;
                    color: $color-blue !important;
                    font-family: Ubuntu, serif;
                    font-weight: 500;
                }
                .gotofyndery{
                    display: none;
                    z-index: 2;
                    .fa{
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                span.bg {
                    opacity: 1;
                    transition: opacity 0.35s ease-in-out;
                }
                .gotofyndery{
                    display: block;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: calc(50% - 10px);
                    width: calc(100% - 20px);
                    -webkit-transition: opacity 0.35s ease-in-out;
                    -o-transition: opacity 0.35s ease-in-out;
                    transition: opacity 0.35s ease-in-out;
                }
            }
        }
    }
}

// Navbar
.navbar{
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: 768px) {
        padding: 0 90px;
    }
    @media (min-width: 1200px) {
        padding: 0 45px;
    }
    @media (min-width: 1400px) {
        padding: 0 90px;
    }
    .navbar-nav{
        .nav-item{
            margin: 0 0 0 40px;
        }
        .nav-link{
            font-size: 1rem;
            padding: 15px 0;
            color: $color-blue;
            font-weight: 500;
            @media (min-width: 768px) {
                padding: 25px 0;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
}

// Footer
footer{
    border-top: 1px solid $bg-schlamm;
    background: $bg-light;
    flex-shrink: 0;
    .sub-footer {
        display: table;
        width: 100%;
        .inner{
            padding: 60px 20px;
            @media (min-width: 768px) {
                padding: 90px 100px;
            }
            @media (min-width: 1200px) {
                padding: 45px 50px;
            }
            @media (min-width: 1400px) {
                padding: 90px 100px;
            }
        }
        p{
            font-size: 16px;
            margin: 0;
        }
        ul.social{
            font-size: 18px;
            padding: 0;
            margin: 0 0 -5px 0;
            line-height: 1;
            li{
                margin: 0 5px 5px 5px;
                display: inline-block;
                line-height: 26px;
            }
        }
    }
}

// Sonstiges
#scrollToTopBtn{
    position: fixed;
    display: none;
    z-index: 1001;
    right: 20px;
    bottom: 20px;
    transition: background 150ms ease-in-out;
    -webkit-backface-visibility: hidden;
    .btn{
        background: rgba(0,54,89, 0.8);
        color: #fff;
        padding: 0.5rem 0.75rem;
    }
}

.backend{
    background-color: $bg-schlamm;
    color: rgb(77, 60, 50);
}